import anchorMixins from './anchorMixins';

export default {
  install(Vue) {
    Vue.mixin({
      mixins: [
        anchorMixins
      ]
    })
  }
}