<!--
支持外链的RouterLink, 只需要包装一次默认的router-link.
-->
<template>
  <a v-if="!to.startsWith('/')" :href="to" :target="target">
    <slot></slot>
  </a>
  <router-link v-else v-bind="vProps">
    <slot></slot>
  </router-link>
</template>
<script>
  export default {
    name: 'XRouterLink',
    props: {
      to: {
        type: String,
        default: '/',
        required: true,
      },
      target: {
        type: String,
        default: '_blank'
      },
    },
    computed: {
      vProps() {
        return {...this.$props, ...this.$attrs}
      }
    },

  }
</script>