
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
import officialQrcode from '@/assets/images/qr-code.jpg'

// 创建一个新的 store 实例
const store = new Vuex.Store({
  state: {
    globalOptions: {
      title: '新海丰（青岛）跨境电商服务有限公司',
      subTitle: '跨境电商服务平台',
      subTitleEn: 'SITC E-commerce Service (Qingdao) Co.,Ltd.',
      phone: '18560633976',
      address: '青岛市市南区中山路70号2楼',
      email: 'qdjzwkjds@163.com',
      serveTime: '24小时',
      ICPCode: '鲁ICP备2022015178号-1'
    },
    aboutUs: {
      name: '',
      remark: '',
      establishedDate: '',
      area: '',
      companyCount: '',
      address: '',
      email: '',
      mobile: '',
      image: '',
      officialQrcode,
    }
  },
  getters: {
    options (state) {
      return state.globalOptions;
    },
  },
  mutations: {
    setAboutUs (state, data) {
      state.aboutUs = data
    }
  },
  actions: {
    setAboutUs (context, data) {
      context.commit('setAboutUs', data)
    }
  }
});

export default store;