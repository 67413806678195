<template>
  <div class="Footer">
    <div class="content_center">
      <div class="Footer-left">
        <div class="title_cn">{{ globalOptions.title }}</div>
        <div class="title_en">{{ globalOptions.subTitleEn }}</div>
        <div class="address">地址：{{ aboutUs.address }}</div>
        <div class="email">邮箱：{{ aboutUs.email }}</div>
        <div class="consult" v-if="!isHide">
          <div class="phone">
            <p>{{ globalOptions.phone }}</p>
            <p>{{ globalOptions.serveTime }}服务热线</p>
          </div>
          <!-- <div class="button" @click="handleClickConsult">立即咨询</div> -->
        </div>
      </div>
      <div class="Footer-center" v-if="!isHide">
        <template v-for="item in links">
          <div :key="item.title" class="link_item">
            <div class="link_title">{{ item.title }}</div>
            <template v-for="link in item.list">
              <com-router-link :key="link.name" :to="link.path" :target="link.target">{{ link.name }}</com-router-link>
              <!-- <p :key="link.name">
              </p> -->
            </template>
          </div>
        </template>
      </div>
      <div class="Footer-right">
        <div class="link_title">联系平台</div>
        <div class="code_img">
          <img :src="aboutUs.officialQrcode" alt="">
        </div>
        <div v-if="!isHide" class="button" @click="handleClickConsult">投诉建议</div>
      </div>
    </div>
    <div class="practice">{{ globalOptions.ICPCode }}</div>
    <el-dialog
      :visible.sync="consultVisible"
      title="反馈信息"
      center
      width="460px">
      <div class="consult-content">
        <p class="desc">请认真填写以下内容，以方便我们与您取得联系</p>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px"  label-position="top">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model.number="ruleForm.phone" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
          <el-form-item label="反馈信息" prop="remark">
            <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入您想反馈的内容信息"></el-input>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="verfiyCodeVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="verfiyCode">
        <slide-verify
          :l="slideObj.l"
          :r="slideObj.r"
          :w="slideObj.w"
          :h="slideObj.h"
          slider-text="请拖动滑块完成验证再确认提交"
          @success="onSuccess"
          :imgs="imgs"
          :accuracy='5'
        ></slide-verify>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import ComRouterLink from './ComRouterLink.vue';
  import { mapState  } from 'vuex'
  import officialQrcode from '@/assets/images/qr-code.jpg'
  export default {
    name: 'Footer',
    props: {
      isHide: {
        type: Boolean,
        default: false
      },
    },
    components: {
      ComRouterLink,
    },
    data() {
      return {
        links: [
          {
            title: '关于我们',
            list: [
              {
                name: '企业简介',
                path: '/about?anchor=anchor_a'
              },
              {
                name: '园区概要',
                path: '/about?anchor=anchor_b'
              },
              {
                name: '产业规模',
                path: '/about?anchor=anchor_c'
              },
              {
                name: '区位优势',
                path: '/about?anchor=anchor_f'
              },
            ]
          },
          {
            title: '关务服务',
            list: [
              {
                name: '业务类型',
                path: '/customs?anchor=anchor_a'
              },
              {
                name: '业务优势',
                path: '/customs?anchor=anchor_b'
              },
              {
                name: '报关服务',
                path: this.$customsUrl,
                target: '_blank',
              },
              {
                name: '四大中心',
                path: '/customs?anchor=anchor_d'
              },
            ]
          },
          {
            title: '业务领域',
            list: [
              {
                name: '集散中心',
                path: '/business?anchor=anchor_a'
              },
              {
                name: '保税物流园',
                path: '/business?anchor=anchor_b'
              },
            ]
          },
          {
            title: '新闻动态',
            list: [
              {
                name: '热点新闻',
                path: '/news?tab=anchor_5'
              },
              {
                name: '政策解读',
                path: '/news?tab=anchor_4'
              },
              {
                name: '行业资讯',
                path: '/news?tab=anchor_3'
              },
              {
                name: '国外资讯',
                path: '/news?tab=anchor_2'
              },
            ]
          },
          // {
          //   title: '政策法规',
          //   list: [
          //     {
          //       name: '跨境模式',
          //     },
          //     {
          //       name: '业务指导',
          //     },
          //     {
          //       name: '涉税介绍',
          //     },
          //     {
          //       name: '政策解读',
          //     },
          //   ]
          // },
          {
            title: '应用入口',
            list: [
              {
                name: '9610申报',
                path: this.$customsUrl,
                target: '_blank',
              },
              {
                name: '9710申报',
                path: this.$customsUrl,
                target: '_blank',
              },
              {
                name: '9810申报',
                path: this.$customsUrl,
                target: '_blank',
              },
              {
                name: '1210申报',
                path: this.$customsUrl,
                target: '_blank',
              },
            ]
          }
        ],
        officialQrcode,
        consultVisible: false,
        ruleForm: {
          name: '',
          phone: '',
          remark: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入您的姓名', trigger: 'blur' },
            { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入您手机号', trigger: 'blur' },
          ],
        },
        verfiyCodeVisible: false,
        slideObj: {
          l: 42, //滑动碎片的大小
          r: 10, //滑动碎片的圆角
          w: 360, //画布的宽
          h: 180, //画布的高
        },
        imgs: [
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.pconline.com.cn%2Fimages%2Fupload%2Fupc%2Ftx%2Fitbbs%2F1411%2F03%2Fc11%2F40513136_1415006215595.jpg&refer=http%3A%2F%2Fimg.pconline.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=66cafeed4b05e3cc2337bd619564552a",
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F0146425bbdc6efa801213dead7d60e.jpg%401280w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=e11d669624d5ac428996e933f67f5d93",
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201804%2F28%2F20180428190056_cnorp.jpg&refer=http%3A%2F%2Fb-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=a174254b82136a546d08f4e903dddb59",
          "https://gss0.baidu.com/70cFfyinKgQFm2e88IuM_a/baike/pic/item/14ce36d3d539b6005fd3d015e550352ac65cb777.jpg",
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fbkimg.cdn.bcebos.com%2Fpic%2F18d8bc3eb13533fa828b4f630598ea1f4134970aed60&refer=http%3A%2F%2Fbkimg.cdn.bcebos.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=fb598fe1de3576cf76269f913d9fad0b",
        ], //可以将所需的图片放在一个数组中，刷新后组件会自动随机抽取图片
      }
    },
    computed: {
      ...mapState ({
          globalOptions: state => state.globalOptions,
          aboutUs: state => state.aboutUs,
      }),
    },
    mounted () {
      this.getAboutUs();
    },
    methods: {
      async getAboutUs() {
        const res = await this.$request.get(`/aboutUs/1`);
        this.details = res.data;
        this.$store.dispatch('setAboutUs', res.data)
      },
      handleClickConsult() {
        this.consultVisible = true;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.verfiyCodeVisible = true;
          } else {
            return false;
          }
        });
      },
      async onSuccess(){
        const res = await this.$request.post(`/feedback/create`, this.ruleForm);
        if (res.success) {
          this.verfiyCodeVisible = false;
          this.consultVisible = false;
          this.$message.success('您的信息已提交成功');
        } else {
          this.$message.error('提交失败');
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.Footer{
  width: 100%;
  padding: 50px 0 80px;
  background-color: #00132E;
  box-sizing: border-box;
  .content_center{
    width: 1520px;
    display: flex;
    justify-content: space-between;
  }
  .practice{
    border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
    margin-top: 20px;
    padding-top: 20px;
    font-size: 12px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
  }
  &-left{
    width: 500px;
    .title_cn{
      font-size: 30px;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 37px;
    }
    .title_en{
      margin-bottom: 36px;
      font-size: 14px;
      font-weight: 400;
      color: #C1C1C9;
    }
    .address,
    .email{
      font-size: 14px;
      color: #C1C1C9;
      line-height: 20px;
    }
    .consult{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .phone{
        p:first-child{
          font-size: 24px;
          font-family: AppleSystemUIFont;
          color: #FFFFFF;
          line-height: 28px;
        }
        p:last-child{
          font-size: 14px;
          color: #C1C1C9;
          line-height: 20px;
        }
      }
    }
  }
  &-center{
    flex: 1;
    display: flex;
    justify-content: center;
    .link_item{
      margin-right: 70px;
      display: flex;
      flex-direction: column;
      a{
        margin-bottom: 16px;
        font-size: 14px;
        color: #C1C1C9;
        cursor: pointer;
        &:hover{
          color: #0F5FD5;
        }
      }
    }
  }
  &-right{
    width: 80px;
    .code_img{
      width: 80px;
      height: 80px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .button{
      width: 80px;
      height: 28px;
      line-height: 28px;
      margin-top: 20px;
      background: #3484FB;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
  }
  .link_title{
    margin-bottom: 20px;
    font-size: 18px;
    color: #FFFFFF;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1520px) {
  .Footer {
    .content_center{
      width: 1366px;
    }
    &-center{
      .link_item{
        margin-right: 50px;
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .Footer {
    .content_center{
      width: 1200px;
    }
    &-center{
      .link_item{
        margin-right: 20px;
      }
    }
  }
}
.consult-content{
  .desc{
    margin-bottom: 20px;
    color: #4A4A53;
    text-align: center;
  }
  .btn{
    display: flex;
    justify-content: center;
    ::v-deep(.el-button){
      span{
        color: #fff;
      }
    }
  }
}
</style>