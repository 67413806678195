import axios from 'axios';
import { Message } from 'element-ui';

const service = axios.create({
  baseURL: '/app/',
  timeout: 300000
})

service.interceptors.request.use(
  config => {
    // config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    if(config.url.startsWith('/data') || config.url.startsWith('/timeLine/api') || config.url.startsWith('/logistics/api')) {
      config.baseURL = '';
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 0) {
      if(res.code === 401) {
        Message.error(res.msg);
      } else if(res.code === 200 || response.config.url.startsWith('/timeLine/api') || response.config.url.startsWith('/logistics/api')) {
        return res;
      } else {
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res;
    }
  },
  error => {
    const res = error.response
    let message = '请求服务错误.'
    if (res.data) {
      message = res.data.message
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
