const anchorMixins = {
  watch: {
    '$route': {
      immediate: true,
      handler(route) {
        if(route && route.name){
          if(route.query?.anchor) {
            this.goContactUs();
          }
        }
      }
    }
  },
  methods: {
    goContactUs() {
      const anchor = this.$route.query.anchor;
      // 跳转到指定位置并且平滑滚动
      this.$nextTick(() => {
        document
          .getElementById(anchor)
          .scrollIntoView({ block: "start", behavior: "smooth" });
      });
    }
  },
};
export default anchorMixins;