<template>
  <div class="BlockTitle">
    <div class="BlockTitle-title_EN">{{ enName }}</div>
    <div class="BlockTitle-title_CN">{{ cnName }}</div>
    <div class="BlockTitle-line"></div>
    <template v-if="isDesc">
      <div class="BlockTitle-desc">
        <template v-for="(item, index) in descList">
          <p :key="index">{{ item }}</p>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: "BlockTitle",
    props: {
      cnName: {
        type: String,
        default: ''
      },
      enName: {
        type: String,
        default: ''
      },
      isDesc: {
        type: Boolean,
        default: false,
      },
      descList: {
        type: Array,
        default: () => []
      }
    },
  }
</script>

<style lang="scss" scoped>
.BlockTitle {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  &-title_EN{
    position: absolute;
    top: 8px;
    width: 100%;
    font-size: 58px;
    font-family: AppleSystemUIFont;
    color: rgba($color: #000000, $alpha: 0.05);
    text-align: center;
  }
  &-title_CN{
    font-size: 42px;
    font-weight: 600;
    color: #171717;
    text-align: center;
    margin-bottom: 30px;
  }
  &-line {
    width: 60px;
    height: 4px;
    margin: 0 auto;
    background-color: #3484FB;
  }
  &-desc{
    line-height: 22px;
    margin: 20px auto 0;
    font-size: 16px;
    color: #5F5F6A;
    text-align: center;
  }
}
</style>