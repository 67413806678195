import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/reset.css'

import './svgIcons'

import request from './utils/request';

import GlobalMixins from './mixins';

Vue.use(GlobalMixins);


// 移动端适配
// import 'lib-flexible/flexible.js'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import animated from "animate.css";
Vue.use(animated)


Vue.config.productionTip = false;

Vue.prototype.$ossUrl = process.env.VUE_APP_OSSURL;
Vue.prototype.$ossHash = `?${process.env.VUE_APP_OSSHASH}`;
Vue.prototype.$customsUrl = `${process.env.VUE_APP_CUSTOMSURL}`;
Vue.prototype.$request = request;
Vue.use(Element)

import Footer from './components/Footer.vue';
Vue.component('Footer', Footer);
import SubTopBanner from './components/SubTopBanner.vue';
Vue.component('SubTopBanner', SubTopBanner);
import BlockTitle from './components/BlockTitle.vue';
Vue.component('BlockTitle', BlockTitle);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
