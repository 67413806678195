import Vue from 'vue';

import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们',
      subTitle: '联系我们'
    },
    component: () => import('../views/About/index.vue')
  },
  {
    path: '/customs',
    name: 'Customs',
    meta: {
      title: '关务服务'
    },
    component: () => import('../views/Customs/index.vue')
  },
  {
    path: '/business',
    name: 'Business',
    meta: {
      title: '业务领域'
    },
    component: () => import('../views/Business/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    redirect: '/news/list',
    meta: {
      title: '新闻动态'
    },
    component: () => import('../views/News/index.vue'),
    children: [
      {
        path: 'list',
        name: 'News.List',
        meta: {
          title: '新闻动态'
        },
        component: () => import('../views/News/List.vue'),
      },
      {
        path: 'detail/:id',
        name: 'News.Details',
        component: () => import('../views/News/Details.vue'),
      }
    ]
  },
  {
    path: '/statistics',
    name: 'Statistics',
    redirect: '/statistics/business',
    component: () => import('../views/Statistics/index.vue'),
    children: [
      {
        path: 'today',
        name: 'Statistics.TodayData',
        component: () => import('../views/Statistics/TodayData/index.vue'),
      },
      {
        path: 'business',
        name: 'Statistics.Bussiness',
        component: () => import('../views/Statistics/Bussiness/index.vue'),
      },
      {
        path: 'client',
        name: 'Statistics.Client',
        component: () => import('../views/Statistics/Client/index.vue'),
      },
      {
        path: 'country',
        name: 'Statistics.Country',
        component: () => import('../views/Statistics/Country/index.vue'),
      },
      {
        path: 'cd',
        name: 'Statistics.CustomDistrict',
        component: () => import('../views/Statistics/CustomDistrict/index.vue'),
      },
    ]
  },
  {
    path: '/screen/lss',
    name: 'Screen.LargeScreenSynthesis',
    component: () => import('../views/Statistics/LargeScreenSynthesis/index.vue'),
  },
  {
    path: '/screen/9610',
    name: 'Screen.Dashboard9610',
    component: () => import('../views/Statistics/Dashboard9610/index.vue'),
  },
  {
    path: '/logistics',
    name: 'Logistics',
    component: () => import('../views/Logistics/index.vue'),
    beforeEnter: (to, form, next) => {
      if(/(iPhone|iPad|iOS|Android)/i.test(navigator.userAgent)){
        next('/m-logistics');
      } else {
        next();
      }
    }
  },
  {
    path: '/m-logistics',
    name: 'MLogistics',
    component: () => import('../views/MLogistics/index.vue'),
    beforeEnter: (to, form, next) => {
      if(!/(iPhone|iPad|iOS|Android)/i.test(navigator.userAgent)){
        next('/logistics');
      } else {
        next();
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginRegister/index.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/LoginRegister/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
