<template>
  <div class="SubTopBanner">
    <img :src="topBannerUrl" alt="">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'SubTopBanner',
    props: {
      topBannerUrl: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>
.SubTopBanner{
  position: relative;
  width: 100%;
  max-height: 700px;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>